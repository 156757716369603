import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GitHubProject } from '../interfaces/git-hub-project';

@Injectable({
  providedIn: 'root'
})
export class GithubService {
  private url = 'https://api.github.com/users/HillLuke/repos';
  private httpOptions = {
    headers: new HttpHeaders({ 
      'Accept': 'application/vnd.github.v3+json,application/vnd.github.mercy-preview+json'
    }),
  };

  constructor(
    private http: HttpClient
  ) { }

  getGitHubProjects(): Observable<GitHubProject[]>{
    return this.http.get<GitHubProject[]>(this.url, this.httpOptions);
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {AccordionModule} from 'primeng/accordion';     
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {ButtonModule} from 'primeng/button';
import {CardModule} from 'primeng/card';
import {MenubarModule} from 'primeng/menubar';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { GitHubProjectsComponent } from './components/git-hub-projects/git-hub-projects.component';
import { GitHubProjectComponent } from './components/git-hub-project/git-hub-project.component';

@NgModule({
  declarations: [
    AppComponent,
    GitHubProjectsComponent,
    GitHubProjectComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    AccordionModule,
    ScrollPanelModule,
    CardModule,
    ButtonModule,
    MenubarModule,
    ProgressSpinnerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, Input, OnInit } from '@angular/core';
import { GitHubProject } from './../../interfaces/git-hub-project';

@Component({
  selector: 'app-git-hub-project',
  templateUrl: './git-hub-project.component.html',
  styleUrls: ['./git-hub-project.component.scss']
})
export class GitHubProjectComponent implements OnInit {
 @Input() gitHubProject : GitHubProject;
 hasLogo = true;
 imageSrc = '';

  constructor() { }

  ngOnInit(): void {
    this.imageSrc = this.getLogo();
  }

  getLogo(): string{
    switch (this.gitHubProject.topics[0]) {
      case 'angular2':
        return 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Angular_full_color_logo.svg/1024px-Angular_full_color_logo.svg.png';    
      case 'reactjs':
        return 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/512px-React-icon.svg.png';
      case 'unity':
        return 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Unity_Technologies_logo.svg/275px-Unity_Technologies_logo.svg.png';     
      case 'netcore3':
        return 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/.NET_Core_Logo.svg/512px-.NET_Core_Logo.svg.png';     
      case 'netcore3-webapi':
        return 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/.NET_Core_Logo.svg/512px-.NET_Core_Logo.svg.png';
      case 'blazor':
        return 'https://upload.wikimedia.org/wikipedia/commons/d/d0/Blazor.png';
      default:
        this.hasLogo = false;
        return ''
    }


  }

}

import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { GitHubProject } from '../../interfaces/git-hub-project';
import { GithubService } from '../../services/github.service';

@Component({
  selector: 'app-git-hub-projects',
  templateUrl: './git-hub-projects.component.html',
  styleUrls: ['./git-hub-projects.component.scss']
})
export class GitHubProjectsComponent implements OnInit {
  isLoading = true;
  GitHubProjects : GitHubProject[];

  constructor(private GitHubService: GithubService) { }

  ngOnInit(): void {
    this.GitHubService.getGitHubProjects().subscribe(res => {
      this.GitHubProjects = res;
      this.isLoading = false;
      this.GitHubProjects.sort((a, b) => {
        return (Date.parse(a.pushed_at) > Date.parse(b.pushed_at) ? -1 : 1);
      });
    });
  }
}
